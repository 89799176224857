<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
<!--            <el-form-item label="">-->
<!--              <el-input v-model="searchForm.username" size="mini" placeholder="请输入姓名"></el-input>-->
<!--            </el-form-item>-->
          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button  size="mini" type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  size="mini" type="primary" @click="clear">清空</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      ref="elTable"
      :header-cell-style="{backgroundColor:'#f4f4f5',textAlign:'center'}"
      :height="tableHeight"
      border
      style="width: 100%">
      <el-table-column
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80">
      </el-table-column>
      <el-table-column
        align="center"
        prop="username"
        label="用户名"
        min-width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="nickname"
        label="昵称"
        min-width="200">
      </el-table-column>
      <el-table-column
        align="center"
        prop="loginTime"
        label="登录时间"
        min-width="200">
        <template scope="scope">
          {{ fmtDate2(scope.row.loginTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import request from '@/common/utils/request.js'
import util from '@/common/utils/util.js'
import {getUserLoginLogList} from "../../../api/user_login_log";


export default {
  name: "userLoginLog",
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible:false,
      loading: false,
      loadingDiv: null,
      searchForm: {
        username: '',
      },
    }


  },
  watch:{

  },
  mounted() {

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight){
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
    })
    window.onresize = () => {
      this.$nextTick(() => {
          this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
      })
    }
  },
  methods: {
    clear(){
      for(let key in this.searchForm){
        this.searchForm[key]  = ''
      }
      this.getDataList(1);
    },
    fmtDate2(d2, fmt) { // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2)){
        return "";
      }
      return  this.fmtDate(new Date(d2), fmt);
    },
    fmtDate(d, fmt){ // yyyy-MM-dd hh:mm:ss
      if(!fmt){
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+" : d.getMonth()+1,                 //月份
        "d+" : d.getDate(),                    //日
        "h+" : d.getHours(),                   //小时
        "m+" : d.getMinutes(),                 //分
        "s+" : d.getSeconds(),                 //秒
        "q+" : Math.floor((d.getMonth()+3)/3), //季度
        "S"  : d.getMilliseconds()             //毫秒
      };
      if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (d.getFullYear()+"").substr(4 - RegExp.$1.length));
      }
      for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
      }
      return fmt;
    },
    getDataList(pageNum){
      const loadingDiv = util.showLoading()

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      }

      getUserLoginLogList(param).then(res => {
        if(res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }else if(res.code == 1) {
          let data = res.data;
          this.currentPage = pageNum;
          this.total = data.total;
          this.tableData = data.dataList;
        }
        loadingDiv.close();
      }).catch(res => {
        loadingDiv.close();
      })
    },
    search() {
      this.getDataList(1)
    },
    handleCurrentChange(pageNum){
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize;
      this.getDataList(1)
    }
  }
}
</script>

<style scoped lang="scss">

  // @import "~@/common/styles/element-variables.scss";
  .search-box {
    background-color: #FFFFFF;
    min-height: 36px;
    line-height: 36px;
    padding-top: 2px;
    padding-bottom: 6px;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .el-form--inline {
      display: inline-block;
    }
  }
  .pagination-box {
    text-align: left;
    margin-top: 10px;
  }
</style>
<style lang="scss">
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-dialog__header {

  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .el-dialog__footer {
    padding-top: 0px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-tabs--border-card {
    box-shadow: none;
  }
</style>
