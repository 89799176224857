import request from '@/common/utils/request'
import Vue from "vue";

export function getUserLoginLogList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/getUserLoginLogList',
    method: 'post',
    data
  })
}
